<script>
import {APP_NAME} from "@/utils/config";
import * as echarts from 'echarts';
import {indexData, monitorData} from "@/api/common";
import dayjs from "dayjs";
import CountUp from "@/utils/countUp"


export default {
  name: "monitorBoard",
  data() {
    return {
      zoom: 1,
      app_name: APP_NAME,
      dashboardBg: require('@/assets/img/monitor/monitor-bg.png'),
      numBg: require('@/assets/img/monitor/num-bg.png'),
      devBg: require('@/assets/img/monitor/icon-bg-dev.png'),
      //使用天数
      useDay: 0,
      //时间
      date_sh: null,
      time: null,
      //
      dataSummary: null, // farmerSize:农户总量;powerStationSize:完成总电站
      //开发中电站数据概览
      developing: null,
      // 合同签订情况
      signData: null,
      // 农户区域排名
      regionRanking: null,
      region_type: 'city', // area区县
      curRankData: [],
      //近30天开发趋势
      monthData: [],
      //近6个月并网装机容量
      nearSixData: []
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  mounted() {
    let that = this
    this.getData()
    this.getIndexData()
    // 时间
    this.initDateTimeSh()
    // 页面缩放
    that.calcZoom()
    window.addEventListener('resize', () => {
      that.calcZoom()
    })
  },
  beforeDestroy() {
    if (this.date_sh) {
      clearInterval(this.date_sh)
    }
  },
  methods: {
    getIndexData() {
      indexData().then(({useDay}) => {
        this.useDay = useDay
      })
    },
    getData() {
      let that = this
      monitorData().then(res => {
        that.dataSummary = res.dataSummary || null
        that.developing = res.developing || null
        that.signData = res.signData || null
        that.regionRanking = {
          province: res.province,
          city: res.city,
          area: res.area,
        }
        that.curRankData = res.city || []
        that.monthData = res.monthData || []
        that.nearSixData = res.nearSixData || []

        that.$nextTick(() => {
          that.initCountUp()
          that.initContractChart()
          that.initDevFarmerChart()
          that.initConnectChart()
        })
      })
    },
    initCountUp() {
      let that = this
      let farmerCountDom = document.getElementById('farmer-number')
      let farmerCountNum = that.dataSummary?.farmerSize || 0
      let farmerCountUp = new CountUp(farmerCountDom, farmerCountNum, {
        duration: 3,
        separator: '',
        customPrintValue: (val) => {
          let padVal = that.transformNum(val, 6, '')
          for (let i in padVal) {
            farmerCountDom.children[i].innerText = padVal[i]
          }
        }
      })
      if (!farmerCountUp.error) {
        farmerCountUp.start()
      }

      let electronicCountDom = document.getElementById('electronic-number')
      let electronicCountNum = that.dataSummary?.powerStationSize || 0
      let electronicCountUp = new CountUp(electronicCountDom, electronicCountNum, {
        duration: 3,
        separator: '',
        customPrintValue: (val) => {
          let padVal = that.transformNum(val, 6, '')
          for (let i in padVal) {
            electronicCountDom.children[i].innerText = padVal[i]
          }
        }
      })
      if (!electronicCountUp.error) {
        electronicCountUp.start()
      }
    },
    transformNum(val, n, separator) {
      let defaults = []
      for (let i = 1; i <= n; i++) {
        if (separator) {
          if (i % 4 == 0) {
            defaults.push(',')
            continue
          }
        }
        defaults.push('0')
      }
      defaults.reverse()
      let num = val.toString().split('')
      let num_len = num.length
      let pad_len = n - val.length
      if (pad_len > 0) {
        for (let i = 0; i < pad_len; i++) {
          num.unshift(defaults[num_len + i - 1])
        }
      }
      return num
    },
    // 时间
    initDateTimeSh() {
      this.date_sh = setInterval(this.getTime, 1000);
    },
    getTime() {
      let date = dayjs().format("YYYY-MM-DD")
      let hh = new Date().getHours();
      let mf =
          new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
      let ss =
          new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();
      this.time = `${date} ${hh}:${mf}:${ss}`
    },
    //区县排名类型切换
    handleRegionType(key) {
      if (!this.regionRanking) return
      if (this.region_type !== key) {
        this.region_type = key
        this.curRankData = this.regionRanking[key] ? [...this.regionRanking[key]] : [];
      }
    },
    // 合同饼状图
    initContractChart() {
      let that = this
      if (!that.signData) return
      let myChart = that.$echarts.init(document.getElementById('contractChart'))
      let option = {
        series: [
          {
            name: '签约',
            type: 'pie',
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                position: 'center',
                formatter: '{active|签约方式}',
                rich: {
                  active: {
                    fontSize: 16,
                    color: '#FFFFFF',
                    lineHeight: 19,
                  },
                }
              },
              emphasis: {show: true}
            },
            emphasis: {
              label: {show: false, fontSize: 12},
            },
            labelLine: {
              show: false
            },
            data: [
              {value: (that.signData.onLine || 0), name: '线上合同', itemStyle: {color: '#59FFFF'}},
              {value: (that.signData.offLine || 0), name: '线下合同', itemStyle: {color: '#F5BC32'}},
            ]
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // 开发情况折线图
    initDevFarmerChart() {
      let that = this, xAxis = [], yAxis = [];
      for (const value of that.monthData) {
        xAxis.push(value.time)
        yAxis.push(value.size)
      }
      xAxis = xAxis.reverse()
      yAxis = yAxis.reverse()
      let myChart = that.$echarts.init(document.getElementById('farmerDevChart'))
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis,
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,0.65)'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,0.65)'
            }
          }
        },
        series: [
          {
            name: '新增',
            data: yAxis,
            type: 'line',
            areaStyle: {
              color: '#666C3E'
            },
            itemStyle: {
              normal: {
                color: '#FED74C', // 点的颜色
                lineStyle: {
                  color: '#FED74C' // 线的颜色
                }
              }
            }
          }
        ],
        grid: {
          top: '10px',
          left: '10px',
          right: '20px',
          bottom: '10px',
          containLabel: true
        }
      }
      option && myChart.setOption(option);
    },
    // 并网情况柱状图
    initConnectChart() {
      let that = this, xAxis = [], yAxis = [];
      for (const value of that.nearSixData) {
        xAxis.push(value.time)
        yAxis.push(value.size)
      }
      xAxis = xAxis.reverse()
      yAxis = yAxis.reverse()
      let myChart = that.$echarts.init(document.getElementById('connectChart'))
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,0.65)'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: 'rgba(255,255,255,0.65)'
            }
          }
        },
        series: [
          {
            name: '装机容量',
            data: yAxis,
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 渐变方向：从上到下
                  [
                    {offset: 0, color: '#0EECE4'},
                    {offset: 0.5, color: '#09BCE6'},
                    {offset: 1, color: '#058FE7'}
                  ]
              )

            }
          }
        ],
        grid: {
          top: '10px',
          left: '10px',
          right: '20px',
          bottom: '10px',
          containLabel: true
        }
      };
      option && myChart.setOption(option);
    },
    calcZoom() {
      let that = this
      let w_radio = window.innerWidth / 1920
      let h_radio = window.innerHeight / 1080
      that.zoom = Math.min(w_radio, h_radio)
    },
  }
  ,
}
</script>
<template>
  <div class="monitor-dashboard" id="monitorDashboard">
    <div class="monitor-container"
         :style="'background-image: url(' + dashboardBg + ');zoom: ' + zoom + ';--zoom: ' + zoom + ';'">
      <!-- 顶部 -->
      <div class="top-one">您是尊贵的{{ app_name }}客户，您已使用金昇平台{{ useDay }}天。</div>
      <div class="top-title">光伏开发管理平台</div>
      <div class="top-time">{{ time }}</div>
      <!--数据汇总-->
      <div class="data-sum-box">
        <div class="data-item" style="margin-bottom: 20px">
          <div class="data-name">农户总量</div>
          <div class="data-nums" id="farmer-number">
            <div v-for="(item,index) in 6" :key="index" :style="'background-image: url(' + numBg + ');'">0</div>
          </div>
        </div>
        <div class="data-item">
          <div class="data-name">完成总电量</div>
          <div class="data-nums" id="electronic-number">
            <div v-for="(item,index) in 6" :key="index" :style="'background-image: url(' + numBg + ');'">0</div>
          </div>
        </div>
      </div>
      <!--开发中电站数据-->
      <div class="dev-station-box">
        <div class="station-wrapper">
          <div class="side">
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.init || 0 }}</div>
              <div class="name">用户管理</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.tk || 0 }}</div>
              <div class="name">踏勘阶段</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.ht || 0 }}</div>
              <div class="name">合同管理</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.sj || 0 }}</div>
              <div class="name">设计管理</div>
            </div>
          </div>
          <div class="middle">
            <div class="images">
              <img src="@/assets/img/monitor/icon-dev-station-1.png" alt="" class="image-1">
              <img src="@/assets/img/monitor/icon-dev-station-2.png" alt="" class="image-2">
              <img src="@/assets/img/monitor/icon-dev-station-3.png" alt="" class="image-3">
            </div>
            <div class="num">{{ developing?.sum || 0 }}</div>
            <div class="name">开发中电站数量</div>
          </div>
          <div class="side">
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.wl || 0 }}</div>
              <div class="name">项目物料</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.sg || 0 }}</div>
              <div class="name">施工验收</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.bw || 0 }}</div>
              <div class="name">并网管理</div>
            </div>
            <div class="side-item" :style="'background-image: url(' + devBg + ');'">
              <div class="num">{{ developing?.js || 0 }}</div>
              <div class="name">结算管理</div>
            </div>
          </div>
        </div>
      </div>
      <!--合同签订情况-->
      <div class="contract-box">
        <div class="left-div">
          <div class="left-item" style="margin-top: 0">
            <img src="@/assets/img/monitor/icon-contract.png" alt="">
            <div class="content">
              <div class="name">已签订合同数量</div>
              <div class="num">{{ signData?.over || 0 }}</div>
            </div>
          </div>
          <div class="left-item">
            <img src="@/assets/img/monitor/icon-contract.png" alt="">
            <div class="content">
              <div class="name">待发起合同数量</div>
              <div class="num">{{ signData?.wait || 0 }}</div>
            </div>
          </div>
          <div class="left-item">
            <img src="@/assets/img/monitor/icon-contract.png" alt="">
            <div class="content">
              <div class="name">签订中合同数量</div>
              <div class="num">{{ signData?.signing || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="pie-chart" id="contractChart"></div>
        <div class="chart-desc">
          <div class="desc-item">
            <div class="color-circle online"></div>
            <span class="name">线上合同</span>
            <span class="num online">{{ signData?.onLine || 0 }}</span>
          </div>
          <div class="desc-item" style="margin-top: 16px">
            <div class="color-circle offline"></div>
            <span class="name">线下合同</span>
            <span class="num offline">{{ signData?.offLine || 0 }}</span>
          </div>
        </div>
      </div>
      <!--地图-->
      <div class="map-box">
        <router-view :key="key"/>
      </div>
      <!--农户区域排名-->
      <div class="farmer-rank-box">
        <div class="area-type">
          <div :class="region_type == 'city'? 'active': ''" @click="handleRegionType('city')">市</div>
          <div :class="region_type == 'area'? 'active': ''" @click="handleRegionType('area')">区县</div>
        </div>
        <div class="rank-table">
          <div class="tr header">
            <div class="td-1">排行</div>
            <div class="td-2">区域</div>
            <div class="td-3">户数</div>
          </div>
          <template v-for="(item,index) in curRankData">
            <div class="tr body" v-if="index < 8" :key="index">
              <div class="td-1">{{ 1 + index }}</div>
              <div class="td-2">{{ item.name }}</div>
              <div class="td-3">{{ item.size }}</div>
            </div>
          </template>
          <div class="no-data" v-if="curRankData&&curRankData.length < 1">暂时没有数据</div>
        </div>
      </div>
      <!--开发情况-->
      <div class="farmer-dev-box">
        <div class="chart-desc">
          <div class="name">近30天开发趋势</div>
          <div>
            <span class="color-circle"></span>
            <span class="name">新增数量</span>
          </div>
        </div>
        <div id="farmerDevChart" style="width: 100%;height: 130px;"></div>
      </div>
      <!--并网情况-->
      <div class="farmer-dev-box connect">
        <div class="chart-desc">
          <div class="name">近6个月并网装机容量</div>
          <div>
            <span class="color-circle"></span>
            <span class="name">装机容量</span>
          </div>
        </div>
        <div id="connectChart" style="width:460px;height: 145px;"></div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.monitor-dashboard {
  width: 100vw;
  height: 100vh;
  background: #041A21;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .monitor-container {
    width: 1920px;
    height: 1080px;
    background: #041A21;
    background-size: cover;
    position: relative;

    .top-one {
      position: absolute;
      left: 24px;
      top: 24px;
      z-index: 10;
      font-weight: 500;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
    }

    .top-title {
      width: 643px;
      height: 70px;
      line-height: 70px;
      position: absolute;
      left: 638px;
      top: 11px;
      z-index: 10;
      font-size: 60px;
      color: #ffffff;
      text-align: center;
      font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    }

    .top-time {
      position: absolute;
      top: 30px;
      right: 24px;
      z-index: 10;
      font-size: 16px;
      color: #1FC6FF;
    }

    .data-sum-box, .dev-station-box, .contract-box, .farmer-rank-box, .farmer-dev-box {
      position: absolute;
      z-index: 10;
      background: rgba(31, 198, 255, 0.1);
      border: 1px solid;
      border-image: linear-gradient(360deg, rgba(31, 198, 255, 0.5), rgba(0, 71, 96, 0.5)) 1 1;
    }

    // 数据汇总
    .data-sum-box {
      width: 500px;
      height: 164px;
      top: 172px;
      left: 24px;
      padding: 20px 20px 20px 30px;

      .data-item {
        height: 51px;
        display: flex;
        align-items: center;

        .data-name {
          width: 70px;
          font-size: 14px;
          color: rgba(255, 255, 255, .8);
          text-align: right;
        }

        .data-nums {
          width: calc(100% - 70px);
          padding-left: 25px;
          font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;


          & > div {
            display: inline-block;
            width: 51px;
            height: 51px;
            line-height: 51px;
            text-align: center;
            margin-right: 8px;
            background-size: cover;
            font-size: 40px;
            color: #FED74C;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

    }

    // 开发中电站数据概况
    .dev-station-box {
      width: 500px;
      height: 376px;
      top: 408px;
      left: 24px;
      padding: 20px;

      .station-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .side-item {
          width: 100px;
          height: 78px;
          margin-top: 8px;
          text-align: center;

          &:first-child {
            margin-top: 0;
          }

          .num {
            padding-top: 8px;
            line-height: 25px;
            font-weight: 400;
            font-size: 18px;
            color: #FED74C;
          }

          .name {
            font-weight: 400;
            font-size: 12px;
            color: rgba(255, 255, 255, .8);
            line-height: 20px;
          }
        }

        .middle {
          text-align: center;

          .images {
            width: 204px;
            height: 164px;
            position: relative;

            .image-1 {
              position: absolute;
              top: 8px;
              left: 62px;
              animation: upDown 1.8s infinite;
            }

            .image-2 {
              width: 100%;
              height: 100%;

            }

            .image-3 {
              position: absolute;
              top: 0;
              left: 52px;
              animation: twinkle 1s infinite alternate;
            }

          }

          .num {
            margin-top: 20px;
            font-weight: bold;
            font-size: 20px;
            color: #FED74C;
            line-height: 23px;
          }

          .name {
            margin-top: 4px;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 20px;
          }
        }
      }
    }

    // 上下浮动动画
    @keyframes upDown {
      0% {
        transform: translate(0px, 0px);
      }
      50% {
        transform: translate(0px, -15px);
      }
      100% {
        transform: translate(0px, 0px);
      }
    }

    @keyframes twinkle {
      0% {
        opacity: .2;
      }
      100% {
        opacity: 1;
      }
    }

    // 合同签订情况
    .contract-box {
      width: 500px;
      height: 200px;
      bottom: 24px;
      left: 24px;
      padding: 16px 20px;
      display: flex;
      align-items: center;

      .left-div {
        width: 154px;
        height: 168px;

        .left-item {
          margin-top: 18px;

          & > img {
            width: 40px;
            height: 40px;
            vertical-align: middle;
          }

          .content {
            padding-left: 16px;
            display: inline-block;
            vertical-align: middle;

            .name {
              font-weight: 400;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.8);
              line-height: 16px;
            }

            .num {
              margin-top: 2px;
              font-weight: 400;
              font-size: 18px;
              color: #FED74C;
              line-height: 21px;
            }
          }
        }
      }

      .pie-chart {
        width: 120px;
        height: 120px;
        margin-left: 36px;
        margin-right: 26px;
      }

      .chart-desc {
        .desc-item {
          $color-online: #F5BC32;
          $color-offline: #59FFFF;

          .online {
            background: $color-online;
          }

          .offline {
            background: $color-offline;
          }

          .color-circle {
            width: 12px;
            height: 8px;
            display: inline-block;
            border-radius: 2px;

            &.online {
              background: $color-online;
            }

            &.offline {
              background: $color-offline;
            }
          }

          .name {
            padding-left: 7px;
            padding-right: 7px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, .8);
            line-height: 16px;
          }

          .num {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            background: none;

            &.online {
              color: $color-online;
            }

            &.offline {
              color: $color-offline;
            }
          }

        }
      }
    }

    .map-box {
      position: absolute;
      top: 124px;
      left: 548px;
      width: 824px;
      height: 932px;
    }

    // 农户区域排名
    .farmer-rank-box {
      width: 500px;
      height: 360px;
      top: 172px;
      right: 24px;
      padding: 16px;

      .area-type {
        width: 130px;
        height: 32px;
        line-height: 32px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(14, 236, 228, 1), rgba(5, 143, 231, 1)) 1 1;
        display: flex;
        margin-bottom: 12px;

        & > div {
          width: 64px;
          height: 32px;
          font-size: 14px;
          text-align: center;
          color: #0EECE4;

          &.active {
            color: #FFFFFF;
            background: linear-gradient(180deg, #0EECE4 0%, #058FE7 100%);
          }
        }


      }

      .rank-table {
        .tr {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(89, 255, 255, 0.1);
          font-size: 14px;
          line-height: 32px;
          height: 32px;
          text-align: center;

          &.header {
            background: rgba(31, 198, 255, 0.2);
            color: rgba(255, 255, 255, 0.65);
          }

          &.body {
            color: #fff;
          }


          .td-1 {
            width: 80px;
          }

          .td-2 {
            width: 288px;
          }

          .td-3 {
            width: 100px;
          }
        }

        .no-data {
          color: rgba(255, 255, 255, 0.65);
          text-align: center;
          padding-top: 50px;
          font-size: 14px;
        }
      }
    }

    // 开发情况
    .farmer-dev-box {
      width: 500px;
      height: 180px;
      bottom: 296px;
      right: 24px;
      padding: 16px 20px;

      .chart-desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .name {
          font-size: 12px;
          color: rgba(255, 255, 255, .8);
          line-height: 20px;
        }

        .color-circle {
          display: inline-block;
          width: 7px;
          height: 7px;
          background: #FED74C;
          margin-right: 4px;
        }
      }

      &.connect {
        bottom: 24px;
        height: 200px;

        .color-circle {
          background: linear-gradient(180deg, #0EECE4 0%, #058FE7 100%);;
        }
      }
    }
  }
}

</style>

<style lang="scss">
.monitor-container {
  canvas {
    zoom: calc(1 / var(--zoom));
    transform: scale(var(--zoom));
    transform-origin: 0 0;
  }
}
</style>